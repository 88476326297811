<template>
		
		<div class="site-wrapper">

      <div class="container">

    		  <ul class="scroller">
    		    <li><router-link to="my-dashboard">Dashboard</router-link></li>	    
    			  <li><a @click.prevent="goBack()">Back</a></li>
    		  </ul>

    		  <div v-if="firebaseid != ''" class="container">

    				<div class="fb-details">

    						<h5 class="title is-5 is-uppercase has-text-centered"><font-awesome-icon icon="cog" /> My Profile</h5>
    				  	<hr>

                <div v-if="userData.freeTrial" class="trialInfo">
                  Your Free Trial runs until {{formatDate(userData.trialEndDate,'dddd D MMMM YYYY')}}.
                </div>
    
    				  	<div class="form-group-reg">   
    		          <label class="label-fixed">Full Name:</label>   
    		          <input class="input" type="text" placeholder="Type name" v-model="profile.displayname">
    		        </div>

    		        <!-- <div class="form-group-reg">   
                  <label class="label-fixed">Saved Home Port:</label>   
                  <input class="input" type="text" placeholder="Type Port name" v-model="profile.homeport">
                </div>	 -->

    						<div class="form-group-reg">
    							<label class="label-fixed">My State:</label>
    							<div class="select">
    								<select v-model="profile.mystate">
    									<option disabled value="">Choose state</option>
    									<option v-for="state in states" :value="state.name" :key="state.name">{{ state.name }}</option>
    								</select>
    							</div>
    						</div>

    						<div class="form-group-reg">   
                  <label class="label-fixed">Address:</label>   
                  <input class="input" type="text" placeholder="Type address" v-model="profile.address">
                </div>

                <div class="form-group">      
                  <textarea v-model="profile.emergencyInfo" class="textarea" placeholder="Write your emergency information here..." rows="5"></textarea>
                </div>

    						<!-- <div v-if="userRole != 'free'" class="form-group-reg">   
                  <input class="is-checkradio" id="doesDiving" type="checkbox" v-model="profile.doesDiving">
    							<label for="doesDiving">Do you perform any diving operations?</label>
                </div> -->

                <!-- <div v-if="userRole != 'free'" class="form-group-reg">   
                  <input class="is-checkradio" id="takesPassengers" type="checkbox" v-model="profile.takesPassengers">
                  <label for="takesPassengers">Do you carry passengers on any vessel?</label>
                </div> -->

    						<!-- <div v-if="userRole != 'free'" class="form-group-reg">   
                  <input class="is-checkradio" id="digitalAudits" type="checkbox" v-model="profile.digitalAudits">
    							<label for="digitalAudits">Get FREE AMSA Audit Support?</label>
                </div> -->

                <!-- <div class="buttons">
                	<span class="button is-primary">What is this?</span>
                </div> -->

                <!-- <button :disabled="!changesMade" class="button is-info" @click="saveUserdata">Update Details</button> -->
                <!-- {{usernameChanged}} / {{stateChanged}} / {{addressChanged}} / {{emergencyInfoChanged}} == {{changesMade}} -->
                
                <button :disabled="!changesMade" class="button is-info" @click="saveUserdata">Update Details</button>

    		    </div>

    				<!-- {{userProfile}} -->

    		  </div>

    		  <div v-else class="container buttons">
    		  	<p class="subtitle is-5">Data not loaded. Go back to the Dashboard and try again.</p>
    		  	<router-link class="button is-info" to="my-dashboard">My Dashboard</router-link>
    		  	<!-- <button class="button" @click="reloadData()">Reload Data</button> -->
    		  </div>

      </div>

		</div>

</template>


<script>	

	import firebase from 'firebase/app';
	import { db } from '../main.js';
	import NProgress from 'nprogress';
  import * as moment from 'moment';

	export default {

		name: 'MyDetails',

	  components: {
	  	
	  },

	  props: {
	    
	  },

		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );
		},

		computed: {
			
			// userProfile() {
			// 	return this.$store.getters.getUserProfile;
			// },
			// userRole() {
			// 	return this.userProfile.userrole;
			// },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
			usernameChanged() {
				return this.profile.displayname != this.userData.username;
			},
			homeportChanged() {
				return this.profile.homeport != this.userData.homeport;
			},
			stateChanged() {
				return this.profile.mystate != this.userData.userstate;
			},
			addressChanged() {
				return this.profile.address != this.userData.address;
			},
      emergencyInfoChanged() {
        return this.profile.emergencyInfo != this.userData.emergencyInfo;
      },
			divingChanged() {
				return this.profile.doesDiving != this.userData.doesDiving;
			},
      passengersChanged() {
        return this.profile.takesPassengers != this.userData.takesPassengers;
      },
			changesMade() {
				return this.usernameChanged || this.stateChanged || this.addressChanged || this.emergencyInfoChanged;
			},

		},

		// watch: {
		// 	userProfile: function() {
		// 		this.onUserLogin();
		// 	}
		// },

		data() {
			return {
				firebaseid: '',
				firebaseemail: '',

				profile: {
					displayname: '',
					mystate: '',
					address: '',
          emergencyInfo: '',
					homeport: '',
					abn: '',
					doesDiving: false,
          takesPassengers: false,
					digitalAudits: false,
					businessName: '',
					contactPerson: '',
					currentMaxVessels: 0,
				},
				states: [
	        { name: "Tasmania" },
	        { name: "Victoria" },
	        { name: "New South Wales" },
	        { name: "Queensland" },
	        { name: "South Australia" },
	        { name: "Western Australia" },
	        { name: "Northern Territory" }
	      ],

			}
		},

		methods: {

			onUserLogin: function(user) {
				// let self = this;
				// this.firebaseid = this.userProfile.userid ? this.userProfile.userid : '';
				// this.firebaseemail = this.userProfile.useremail ? this.userProfile.useremail : '';
				// this.profile.displayname = this.userProfile.username ? this.userProfile.username : '';
				// this.profile.currentMaxVessels = this.userProfile.maxVessels ? this.userProfile.maxVessels : 0;
				// this.profile.homeport = this.userProfile.homeport ? this.userProfile.homeport : '';
				// this.profile.mystate = this.userProfile.userstate ? this.userProfile.userstate : '';
				// this.profile.doesDiving = this.userProfile.doesDiving ? this.userProfile.doesDiving : false;
    //     this.profile.takesPassengers = this.userProfile.takesPassengers ? this.userProfile.takesPassengers : false;
				// this.profile.address = this.userProfile.address ? this.userProfile.address : '';
    //     this.profile.emergencyInfo = this.userProfile.emergencyInfo ? this.userProfile.emergencyInfo : '';
        if (user) {
          this.firebaseemail = user.email;
          this.firebaseid = user.uid;
          this.firebasedisplayname = this.userData.username;
          this.profile.displayname = this.userData.username ? this.userData.username : '';
          this.profile.mystate = this.userData.userstate ? this.userData.userstate : '';
          this.profile.address = this.userData.address ? this.userData.address : '';
          this.profile.emergencyInfo = this.userData.emergencyInfo ? this.userData.emergencyInfo : '';
        }

			},

			goBack: function() {
				this.$router.go(-1);
			},

      formatDate : function (sentDate,format) {
        return moment(sentDate).format(format);
      },

			// reloadData: function() {
			// 	this.$store.dispatch('SETUSERPROFILE'); // pulls in full userdata node fields
			// },	

			saveUserdata: function() {
				let self = this;
				let updateArray = {};
				if (this.usernameChanged) { updateArray.username = self.profile.displayname; }
				// if (this.homeportChanged) { updateArray.homeport = self.profile.homeport; }
				if (this.stateChanged) { updateArray.userstate = self.profile.mystate; }
				if (this.addressChanged) { updateArray.address = self.profile.address; }
        if (this.emergencyInfoChanged) { updateArray.emergencyInfo = self.profile.emergencyInfo; }
				// if (this.divingChanged) { updateArray.doesDiving = self.profile.doesDiving; }
    //     if (this.passengersChanged) { updateArray.takesPassengers = self.profile.takesPassengers; }
				console.log(updateArray);

				NProgress.start();
				db.collection('userdata').doc(self.firebaseid).update(updateArray)
				.then(function() {
					self.$router.replace('my-dashboard');
					NProgress.done();
				})
				.catch(function(err) {
					console.log('Error:',err);
					NProgress.done();
				});
			}

			

		}



	}

</script>  




<style lang="scss" scoped>

	.buttons .button {
		margin-top: 0;
	}
	.left {
		margin-bottom: 1rem;
	}
	h3 {
		text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;
	}
	.fb-details {
		margin-bottom: 3rem;
	}
  .trialInfo {
    margin-bottom: 1rem;
    color: green;
  }

</style>